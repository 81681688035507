<template>
  <b-container fluid style="max-width: 900px;">
    <h3 class="mt-3">Privacy Policy</h3>
    <div style="text-align: left;">
      <p>
        We at buzzwaygames.com respect your privacy and are committed to protecting it. This privacy policy explains how
        we
        collect, use and disclose your personal information when you use this website and its games. Please read this
        privacy policy carefully before using this website and its games. By using this website and its games, you consent
        to our collection, use and disclosure of your personal information in accordance with this privacy policy. If you
        do not agree with this privacy policy, you should not use this website and its games.
      </p>
      <h4>
        What information do we collect?
      </h4>
      <p>
        We may collect the following types of information from you when you use this website and its games:
      </p>
      <p>
        - Information you provide to us: We may collect and store any information you voluntarily provide to us when you
        use this website and its games, such as when you register an account, create a profile, upload or share content,
        participate in surveys, contests or promotions, contact us or provide feedback. This information may include your
        name, email address, username, password, age, gender, location, preferences, interests and any other information
        you choose to provide.
      </p>
      <p>
        - Information we collect automatically: We may collect and store certain information automatically when you use
        this website and its games, such as your IP address, device identifier, browser type, operating system, language
        preference, referring URL, pages viewed, time spent, game play data and other statistics. We may use cookies, web
        beacons and other similar technologies to collect this information. You can disable cookies in your browser
        settings, but some features of this website and its games may not function properly without them.
      </p>
      <p>
        - Information we receive from third parties: We may receive and store information about you from third parties
        that provide services to us or to you in connection with this website and its games, such as advertising partners,
        analytics providers, social media platforms or payment processors. This information may include your demographic
        data, online behavior data, interests data or transaction data. We do not control how these third parties collect
        or use your information and are not responsible for their privacy practices. You should review their privacy
        policies before using their services.
      </p>
      <h4>
        How do we use your information?
      </h4>
      <p>
        We may use your information for the following purposes:
      </p>
      <p>
        - To provide and improve this website and its games: We use your information to provide you with access to this
        website and its games and to enhance their functionality, quality and performance.
      </p>
      <p>
        - To personalize your experience: We use your information to tailor this website and its games to your preferences
        and interests and to show you relevant content and advertisements.
      </p>
      <p>
        - To communicate with you: We use your information to send you newsletters, updates, notifications, announcements,
        offers, promotions and other messages related to this website and its games. You can opt out of receiving these
        messages at any time by following the instructions in the messages or by contacting us.
      </p>
      <p>
        - To protect and enforce our rights and interests: We use your information to prevent, detect and address fraud,
        security, legal and technical issues that may arise in connection with this website and its games. We may also use
        your information to enforce our terms of use, privacy policy and any other agreements or policies that govern your
        use of this website and its games.
      </p>
      <p>
        - To comply with the law: We use your information to comply with applicable laws, regulations, court orders or
        legal requests that we receive from authorities or other parties.
      </p>
      <h4>
        How do we share your information?
      </h4>
      <p>
        We may share your information with the following parties for the following purposes:
      </p>
      <p>
        - With our service providers: We may share your information with third parties that provide services to us or to
        you in connection with this website and its games, such as hosting, storage, analytics, advertising, payment
        processing, customer support or other functions. These service providers are contractually obligated to protect
        your information and only use it for the purposes for which we disclose it to them.
      </p>
      <p>
        - With our affiliates: We may share your information with our subsidiaries, parent companies or other companies
        under common control or ownership with us. These affiliates are subject to this privacy policy or similar privacy
        policies that protect your information.
      </p>
      <p>
        - With our partners: We may share your information with third parties that we partner with to offer you certain
        features, products or services on or through this website and its games, such as social media platforms, game
        developers or publishers. These partners may have their own privacy policies that govern their use of your
        information. You should review their privacy policies before using their features, products or services.
      </p>
      <p>
        - With other users: We may share your information with other users of this website and its games when you upload,
        post, share or otherwise make available your content on this website and its games. Your content may be viewed by
        other users and may be subject to their actions or reactions. You should exercise caution when disclosing your
        personal information in your content.
      </p>
      <p>
        - With other parties for legal reasons: We may share your information with other parties when we believe it is
        necessary or appropriate to do so for legal reasons, such as to comply with the law, respond to legal requests,
        protect our rights and interests, prevent or investigate fraud, security, legal or technical issues, or cooperate
        with law enforcement or other authorities.
      </p>
      <p>
        - With other parties for business reasons: We may share your information with other parties when we are involved
        in a merger, acquisition, sale, transfer, reorganization or dissolution of our business or assets. In such cases,
        we will take reasonable steps to ensure that your information is treated securely and in accordance with this
        privacy policy or a similar privacy policy.
      </p>
      <h4>
        How do we protect your information?
      </h4>
      <p>
        We take reasonable measures to protect your information from unauthorized access, use, disclosure, alteration or
        destruction. However, no method of transmission over the Internet or method of electronic storage is 100% secure.
        Therefore, we cannot guarantee the absolute security of your information. You are responsible for maintaining the
        confidentiality of your account and password and for restricting access to your device. You should also be careful
        about how you handle and disclose your personal information online.
      </p>
      <h4>
        How do we respect your choices and rights?
      </h4>
      <p>
        You have certain choices and rights regarding your information, such as:
      </p>
      <p>
        - You can request a copy of your information that we hold by contacting us. We may charge a reasonable fee for
        this service as permitted by law.
      </p>
      <p>
        - You can request that we correct any inaccurate or incomplete information that we hold about you by contacting
        us.
      </p>
      <p>
        - You can request that we erase or restrict the processing of your information that we hold about you by
        contacting us. However, we may not be able to comply with your request if we have a legal or legitimate reason to
        keep or process your information. We will inform you of the reason if that is the case.
      </p>
      <p>
        - You can object to our processing of your information for certain purposes, such as direct marketing, by
        contacting us. We will stop processing your information for those purposes unless we have a legal or legitimate
        reason to continue.
      </p>
      <p>
        - You can withdraw your consent to our processing of your information at any time by contacting us. However, this
        will not affect the lawfulness of our processing before you withdrew your consent.
      </p>
      <h4>
        How do we handle children's information?
      </h4>
      <p>
        This website and its games are not intended for children under the age of 13. We do not knowingly collect, use or
        disclose personal information from children under the age of 13 without verifiable parental consent. If we become
        aware that we have collected personal information from a child under the age of 13 without verifiable parental
        consent, we will delete it as soon as possible. If you are a parent or guardian and you believe that your child
        has provided us with personal information without your consent, please contact us.
      </p>
      <h4>
        How do we update this privacy policy?
      </h4>
      <p>
        We may update this privacy policy from time to time to reflect changes in our practices, technologies, legal
        requirements or other factors. We will post the updated privacy policy on this website and indicate the date of
        the last update at the top of this page. Your continued use of this website and its games after we post the
        updated privacy policy constitutes your acceptance of the updated privacy policy. We encourage you to review this
        privacy policy periodically to stay informed about how we collect, use and share your information.
      </p>
      <h4>
        How do you contact us?
      </h4>
      <p>
        If you have any questions, comments or requests regarding this privacy policy or our privacy practices, please
        contact us at chuckchan33@gmail.com.
      </p>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "PrivacyPolicyView",
  created() { },
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>